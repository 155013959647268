import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import ContentBlock from "../components/ContentBlock"
import SubTitle from "../components/SubTitle"
import UsedTech from "../components/UsedTech"

import "../components/Layout.css"

import DesignCirclePartImage from "../images/mask@3x.png"
import DesignCornerImage from "../images/rectangle-md.svg"
import DesignRectangle from "../images/greener-square.svg"
import DesignDecorationRectangle from "../images/decoration-rectangle.svg"
import DesignDecorationEllipse from "../images/decoration-ellipse.svg"
import FitsfulMainBg from "../images/fitsful-main-bg.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  situationImage,
  orgBenefitsImage,
  supplierBenefitsImage,
  situationTitle,
  situationDescription,
  orgBenefitsTitle,
  orgBenefitsDescription,
  supplierBenefitsTitle,
  supplierBenefitsDescription,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative md:flex container mx-auto py-12 px-10 lg:px-32">
        <div className="lg:w-1/2">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React JS</TechTag>
          </p>
        </div>
        <div className="lg:w-1/2 mt-10 lg:mt-0 sm:w-3/4 items-center z-20 md:flex place-content-center">
          {listImage && (
            <img
              src={listImage.childImageSharp.fixed.src}
              alt=""
              className="mx-auto"
            />
          )}
        </div>
      </div>

      <div className="bg-gray-300">
        <ContentBlock>
          <div>
            <div className="md:flex md:flex-row">
              <div className="md:w-1/2 pb-10 md:px-20 md:py-10 md:pl-0">
                <img
                  src={situationImage.childImageSharp.fixed.src}
                  alt=""
                  className="md:relative bottom-0 right-0"
                />
              </div>
              <div className="md:w-1/2 md:flex items-center">
                <div>
                  <SubTitle bulletPoint>{situationTitle}</SubTitle>
                  <p>{situationDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </ContentBlock>
      </div>

      <ContentBlock
        className="md:flex md:flex-row bg-right-top bg-no-repeat"
        style={{
          backgroundImage: `url(${FitsfulMainBg})`,
        }}
      >
        <div className="md:w-1/2 md:pr-20 relative">
          <SubTitle bulletPoint>{orgBenefitsTitle}</SubTitle>

          <ul className="list-default leading-8 pl-5 text-gray-500">
            {orgBenefitsDescription.map((item, i) => (
              <li key={i} className="pl-4">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-10 md:mt-0 md:w-1/2">
          <img
            src={orgBenefitsImage.childImageSharp.fixed.src}
            alt=""
            className=""
          />
        </div>
      </ContentBlock>

      <div className="bg-gray-300">
        <img
          src={DesignDecorationEllipse}
          alt=""
          className="hidden md:block absolute w-80 opacity-50"
        />
        <ContentBlock className="md:py-0">
          <div className="md:flex md:flex-row py-20">
            <div className="md:w-1/2 md:px-20 md:pl-0">
              <img
                src={supplierBenefitsImage.childImageSharp.fixed.src}
                alt=""
                className="md:relative bottom-0 right-0"
              />
            </div>
            <div className="md:w-1/2 md:flex items-center">
              <div className="mt-8 md:mt-0">
                <SubTitle bulletPoint>{supplierBenefitsTitle}</SubTitle>
                <ul className="list-default leading-8 pl-5 text-gray-500">
                  {supplierBenefitsDescription.map((item, i) => (
                    <li key={i} className="pl-4">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <img
                src={DesignDecorationRectangle}
                alt=""
                className="hidden md:block absolute w-60 opacity-75 left-40 -bottom-8"
              />
            </div>
          </div>
        </ContentBlock>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <UsedTech title={title} AppDev SoftDev />
    </div>
  )
}

const Leis = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default Leis

export const pageQuery = graphql`
  query LeisID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        situationTitle
        situationDescription
        orgBenefitsTitle
        orgBenefitsDescription
        supplierBenefitsTitle
        supplierBenefitsDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        situationImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        orgBenefitsImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        supplierBenefitsImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
